import { axios } from '../helpers/axios'

export const UserService = () => {
  const getUserInfoByAuthCode = async ({ authCode }) => {
    const resp = await axios.get(`/auth/user-info-auth-by-auth-code?auth_code=${authCode}`)
    if (!resp?.data) throw new Error('Ocurrió un error al buscar usuario')
    return resp?.data
  }

  const getUserInfoByEmail = async ({ email }) => {
    const resp = await axios.get(`/auth/user-info-by-email?email=${email}`)
    if (!resp?.data) throw new Error('Ocurrió un error al buscar usuario')
    return resp?.data
  }

  const updateUserEmail = async ({ email, code }) => {
    const resp = await axios.post(
      '/auth/update-user-email',
      {
        code,
        email
      }
    )
    if (!resp?.data) throw new Error('Ocurrió un error al guardar el correo')
    return resp?.data
  }

  const updateUserPhoneNumber = async ({ code, phone, countryCode }) => {
    const resp = await axios.post(
      '/auth/update-user-phone-number',
      { code, phone, countryCode }
    )
    if (resp?.data.error) throw new Error(resp?.data.error)
    return resp?.data
  }

  /**
   * @param {string | undefined} email
   * @param {string | undefined} phone
   * @param {string} userAuthCode
   * @param {'email' | 'sms'} notificationType
   * @return {Promise<any>}
   */
  const sendVerificationCode = async ({
    email,
    phone,
    userAuthCode,
    notificationType
  }) => {
    const resp = await axios.post(
      '/auth/verification-code/send',
      {
        email,
        phone,
        userAuthCode,
        notificationType
      })

    if (!resp?.data) throw new Error('Ocurrió un error al enviar el código de verificación')

    return resp.data
  }

  const updateUserNameByAuthCode = async ({ firstName, lastName, authCode, email }) => {
    const resp = await axios.post(
      '/auth/change-name-by-auth-code',
      {
        first_name: firstName,
        authorizationCode: authCode,
        last_name: lastName,
        email
      })

    if (!resp?.data) throw new Error('Ocurrió un error al enviar el código de verificación')

    return resp.data
  }

  return {
    getUserInfoByAuthCode,
    updateUserEmail,
    getUserInfoByEmail,
    updateUserPhoneNumber,
    updateUserNameByAuthCode,
    sendVerificationCode
  }
}
