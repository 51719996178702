import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

function InputPhoneNumber (props) {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [valid, setValid] = useState(false)
  const [countryCode, setCountryCode] = useState('+52')
  const inputPhoneNumber = useRef()

  useEffect(() => {
    props.setPhoneNumber(phoneNumber)
    props.setValidPhoneNumber(valid)
    props.setCountryCode(countryCode)
  }, [phoneNumber, valid, countryCode])

  const handleChange = () => {
    const phoneNumberValue = inputPhoneNumber.current.value.replace(/\(|\)/g, '').replace(/\s/g, '')
    setValid(validatePhoneNumber(phoneNumberValue))
    setPhoneNumber(phoneNumberValue)
  }

  useEffect(handleChange, [phoneNumber])

  const validatePhoneNumber = phoneNumber => /^[0-9]{10}$/.test(phoneNumber)

  return (
    <div className="mt-1 mb-4 input-container">
      <div style={{ display: 'flex', gap: '8px', width: '100%' }}>
        <div className="input-with-icon">
          <select
          id="country-code"
          value={countryCode}
          onChange={(e) => setCountryCode(e.target.value)}
          style={{
            width: '80px',
            height: '50px',
            borderRadius: '8px',
            border: '1px solid #ced4da',
            padding: '0 8px',
            display: 'flex',
            alignItems: 'center'
          }}
        >
            <option value="+52">+52</option>
            <option value="+1">+1</option>
          </select>
        </div>
        <div className="input-with-icon" style={{ width: 'inherit' }}>
          <label
            form="digit-1"
            style={{ color: '#94A3B8', fontSize: '12px', position: 'absolute', left: '12px', top: '4px' }}>
            Teléfono
          </label>
          <input
            disabled={props.disabled}
            type="tel"
            name="phone-number"
            id="phone-number"
            ref={inputPhoneNumber}
            style={{ paddingTop: '20px', height: '50px' }}
            onChange={handleChange}
            placeholder={props.placeholder}
            className={`form-control input-login ${phoneNumber === '' ? '' : valid ? 'input-success' : 'input-error'}`}/>
          <i className={`fa icon fa-check text-success ${valid ? '' : 'd-none'}`}/>
        </div>
      </div>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '6px' }}>
        <span style={{ color: 'rgb(100, 116, 139);', fontSize: '12px' }}>{(phoneNumber?.length || 0)}/10 caracteres</span>
      </div>
    </div>
  )
}

InputPhoneNumber.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  setPhoneNumber: PropTypes.func,
  setValidPhoneNumber: PropTypes.func,
  setCountryCode: PropTypes.func
}

export default InputPhoneNumber
