import React, { useContext, useEffect, useRef, useState } from 'react'
import ErrorAlert from './common/ErrorAlert'
import InputPhoneNumber from './common/InputPhoneNumber'
import InputSmsCode from './common/InputSmsCode'
import { UserService } from '../services/user-service'
import { LoginService } from '../services/login-service'
import { PageTitle } from './common/page-title'
import { SsoCard } from './common/sso-card'
import { CARD_BODY_COLUMN_DESCRIPTION } from '../helpers/card-body-column-description'
import { useNavigate } from 'react-router-dom'
import { ClientContext } from '../context/client-context'
import { RetryCode } from './common/retry-code'
import { ACCOUNT_SUCCESS_CODES, useAccountOperationResultMessage } from '../hooks/useAccountOperationResultMessage'
import InputText from './common/InputText'
import { MailLabel } from './common/mail-label'
import { Disclaimer } from './common/disclaimer'
import { booleanFromString } from '../helpers/booleanFromString'

function LoginSuccess () {
  const [error, setError] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [countryCode, setCountryCode] = useState('+52')
  const [validPhoneNumber, setValidPhoneNumber] = useState(false)
  const [smsCode, setSmsCode] = useState('')
  const [validSmsCode, setValidSmsCode] = useState(false)
  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [step, setStep] = useState(null)
  const [loading, setLoading] = useState(false)
  const stateFromCookie = sessionStorage.getItem('state')
  const migratedUser = booleanFromString(sessionStorage.getItem('migratedUser'))

  useAccountOperationResultMessage()

  const alreadyRedirectRef = useRef(null)
  const { isMiTienda } = useContext(ClientContext)

  const queryParams = new URLSearchParams(window.location.search)
  const router = useNavigate()

  useEffect(() => {
    const authCode = queryParams.get('authorizationCode')
    const socialNetwork = queryParams.get('socialNetwork')
    const redirect_uri = queryParams.get('redirect_uri')
    const state = queryParams.get('state')
    if (authCode) {
      sessionStorage.setItem('authorizationCode', authCode)
      sessionStorage.setItem('socialNetwork', socialNetwork)
      Boolean(redirect_uri) && sessionStorage.setItem('redirect_uri', redirect_uri)
      Boolean(state) && sessionStorage.setItem('state', state)
      router('/login-success', { replace: true })
    }
    fetchUser().then()
  }, [])

  const fetchUser = async () => {
    setLoading(true)
    try {
      const userResp = await UserService().getUserInfoByAuthCode({ authCode: sessionStorage.getItem('authorizationCode') })
      const step = userResp.validatePhoneNumber
        ? 'validatePhoneNumber'
        : 'redirect'

      const validateNameStep = (!userResp.first_name || userResp.first_name === 'INDEFINIDO' || !userResp.last_name || userResp.last_name === 'INDEFINIDO') && sessionStorage.getItem('socialNetwork')
        ? 'validateName'
        : step

      if (!userResp.email || !sessionStorage.getItem('authorizationCode')) {
        return router('/', { replace: true })
      }

      const nameIfAppleIsProvider = (userResp.email || '').split('@')?.[0]
      const nameDefaultIfFirstNameAbsent = !userResp.first_name || userResp.first_name === 'INDEFINIDO'
        ? ''
        : userResp.first_name

      const first_name = (!userResp.first_name || userResp.first_name === 'INDEFINIDO') && sessionStorage.getItem('socialNetwork') === 'apple'
        ? nameIfAppleIsProvider
        : nameDefaultIfFirstNameAbsent

      const last_name = userResp.last_name === 'INDEFINIDO' || !userResp.last_name ? '' : userResp.last_name

      setName(first_name)
      setLastName(last_name)

      if (sessionStorage.getItem('socialNetwork') && userResp.email) {
        sessionStorage.setItem('email', userResp.email)
      }

      setStep(validateNameStep)
      if (validateNameStep === 'redirect' && !alreadyRedirectRef.current) await redirect()
    } catch (err) {
      setError(err)
      console.error(err)
    } finally {
      setLoading(false)
    }
  }
  const submitName = async () => {
    if (loading) return
    setLoading(true)
    setError(null)
    try {
      const authCode = sessionStorage.getItem('authorizationCode')
      await UserService().updateUserNameByAuthCode({ firstName: name, lastName, authCode, email: sessionStorage.getItem('email') })
      await fetchUser()
    } catch (err) {
      setError(err)
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const submitPhoneNumber = async () => {
    if (loading) return
    setLoading(true)
    setError(null)
    try {
      if (!validPhoneNumber) return
      await UserService().updateUserPhoneNumber({
        code: sessionStorage.getItem('authorizationCode'),
        phone: phoneNumber,
        countryCode
      })

      const stepResp = 'validateSmsCode'
      setStep(stepResp)
    } catch (err) {
      setError(err)
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const skipPhoneValidation = async () => {
    try {
      setError(null)
      await LoginService().omitPhoneValidation({ code: sessionStorage.getItem('authorizationCode') })
      redirect().then()
    } catch (err) {
      setError(err)
      console.error(err)
    }
  }
  const sendSmsCode = async () => {
    if (loading) return
    setLoading(true)
    try {
      setError(null)
      await LoginService().sendSmsCode({ code: sessionStorage.getItem('authorizationCode'), phoneNumber })
    } catch (err) {
      setError(err)
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const submitSmsCode = async () => {
    if (loading) return
    setLoading(true)
    try {
      setError(null)
      await LoginService()
        .updateSmsCode({ code: sessionStorage.getItem('authorizationCode'), verificationCode: smsCode })
      redirect({
        successCode: ACCOUNT_SUCCESS_CODES.PHONE_CONFIRMED
      }).then()
    } catch (err) {
      setError(err)
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  /**
   * @param {{ successCode: string } | undefined} config
   */
  const redirect = async ({ successCode } = {}) => {
    alreadyRedirectRef.current = true
    const redirectUri = sessionStorage.getItem('redirect_uri')
    sessionStorage.removeItem('redirect_uri')
    const state = sessionStorage.getItem('state')
    sessionStorage.removeItem('state')
    const code = sessionStorage.getItem('authorizationCode')
    sessionStorage.removeItem('authorizationCode')
    sessionStorage.removeItem('client_id')
    sessionStorage.removeItem('socialNetwork')
    sessionStorage.removeItem('email')

    let url = `${redirectUri}?state=${state}&code=${code}`
    if (successCode) {
      url = `${url}&successCode=${successCode}`
    }

    window.location = url
  }

  const pageTitleByStep = {
    validatePhoneNumber: 'Validar tu número telefónico',
    validateSmsCode: 'Introduce el código de verificación',
    validateEmail: 'Es necesario vincular un correo electrónico a su cuenta',
    validateName: 'Crear una nueva cuenta'
  }

  const titleByStep = {
    validatePhoneNumber: 'Ingresa tu número de teléfono',
    validateEmail: 'Ingresa tu email',
    validateSmsCode: 'Ingresa el código de verificación',
    validateName: 'Crear una nueva cuenta'
  }

  return (
    <>
      <PageTitle title={titleByStep[step]} subtitle={step ? pageTitleByStep[step] : ''} />
      <SsoCard>
        {(loading && !step) &&
          <div className='text-center'>
            <div className="spinner-border text-danger" style={{ width: '3rem', height: '3rem' }} role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        }
        <div className="row justify-content-center mt-1" style={{ marginBottom: 0 }}>
          <div className={`${CARD_BODY_COLUMN_DESCRIPTION}`}>
            {(error && step !== 'validatePhoneNumber') &&
              <ErrorAlert
                onCloseAlert={() => setError(null)}
                message={error.message}/>
            }
          </div>
        </div>
        {step === 'validateName'
          ? <div className="row justify-content-center">
            <div className={CARD_BODY_COLUMN_DESCRIPTION}>
              <MailLabel authenticationStrategy={sessionStorage.getItem('socialNetwork')} email={sessionStorage.getItem('email')} />
            </div>
            <form
              onSubmit={event => {
                event.preventDefault()
                submitName().then()
              }}
              className={`${CARD_BODY_COLUMN_DESCRIPTION} mt-2`}>
              <InputText
                id='firstName'
                value={name}
                label={'Nombre'}
                setText={setName}
                setValidText={() => {}}
                placeholder="Ingresa tu nombre" />
              <InputText
                id='lastName'
                value={lastName}
                label={'Apellidos'}
                setText={setLastName}
                setValidText={() => {}}
                placeholder="Ingresa tus apellidos" />
              <button type="submit" name="submit" hidden disabled={loading}/>
            </form>
            <div className={CARD_BODY_COLUMN_DESCRIPTION}>
              <button
                onClick={() => submitName()}
                disabled={!name || !lastName || loading}
                className={'button btn btn-login w-100' + (isMiTienda ? ' mi-tienda' : '')}>
                {loading && <span className="spinner-border spinner-border-sm" style={{ marginRight: '6px' }} role="status" aria-hidden="true"></span>}
                Confirmar
              </button>
            </div>
          </div>
          : null
        }
        {step === 'validatePhoneNumber'
          ? <div className="row justify-content-center validate-phone-number">
            <form
              onSubmit={event => {
                event.preventDefault()
                submitPhoneNumber().then()
              }}
              className={`${CARD_BODY_COLUMN_DESCRIPTION} mt-2`}>
              <label
                htmlFor="phone-number"
                className="form-label"
                style={{ color: '#64748B', textAlign: 'center', marginBottom: '1rem' }}>
                Solo un paso más para asegurar tu cuenta y enviarte promociones y notificaciones sobre tus pedidos.
              </label>
              {error &&
                <ErrorAlert
                  onCloseAlert={() => setError(null)}
                  message={error.message} />
              }
              <InputPhoneNumber
                setPhoneNumber={setPhoneNumber}
                setValidPhoneNumber={setValidPhoneNumber}
                setCountryCode={setCountryCode}
              />
              <button disabled={!validPhoneNumber || loading} type="submit" name="submit" hidden/>
            </form>
            <div className={CARD_BODY_COLUMN_DESCRIPTION}>
              <button
                onClick={submitPhoneNumber}
                disabled={!validPhoneNumber || loading}
                className={'button btn btn-login w-100' + (isMiTienda ? ' mi-tienda' : '')}>
                {loading && <span className="spinner-border spinner-border-sm" style={{ marginRight: '6px' }} role="status" aria-hidden="true"></span>}
                Enviar código
              </button>
            </div>
            {migratedUser && (
              <div className="col-sm-6 col-md-6 col-lg-6">
                <a
                  style={{ color: '#94A3B8', textDecoration: 'none' }}
                  onClick={skipPhoneValidation}
                  className="btn btn-link signup w-100 mt-2">
                  <strong>Saltarme este paso</strong> <i className="fa-solid fa-arrow-right"></i>
                </a>
              </div>
            )}
          </div>
          : null
        }
        {step === 'validateSmsCode'
          ? <div className="row justify-content-center">
            <form
              onSubmit={e => {
                e.preventDefault()
                submitSmsCode().then()
              }}
              className={`${CARD_BODY_COLUMN_DESCRIPTION} mt-3`}>
              <InputSmsCode
                label={<>Se envió un código de 6 dígitos a <strong>{phoneNumber}</strong></>}
                setSmsCode={setSmsCode}
                setValidSmsCode={setValidSmsCode} />
              <button type="submit" name="submit" hidden/>
            </form>
            <RetryCode resendCode={sendSmsCode}/>
            <div className={CARD_BODY_COLUMN_DESCRIPTION}>
              <button
                className={'button btn btn-login w-100' + (isMiTienda ? ' mi-tienda' : '')}
                disabled={!validSmsCode || loading}
                onClick={submitSmsCode}>
                {loading && <span className="spinner-border spinner-border-sm" style={{ marginRight: '6px' }} role="status" aria-hidden="true"></span>}
                Confirmar
              </button>
            </div>
            {migratedUser && (
              <div className="col-sm-6 col-md-6 col-lg-6">
                <a
                  style={{ color: '#94A3B8', textDecoration: 'none' }}
                  onClick={skipPhoneValidation}
                  className="btn btn-link signup w-100 mt-2">
                  <strong>Saltarme este paso</strong> <i className="fa-solid fa-arrow-right"></i>
                </a>
              </div>
            )}
          </div>
          : null
        }
        <div className="row justify-content-center">
          {stateFromCookie && <Disclaimer isMiTienda={isMiTienda} />}
        </div>
      </SsoCard>
    </>
  )
}

export default LoginSuccess
